import React from "react";
import { Helmet } from "react-helmet"; // Import react-helmet
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Hollywoodd from "../../components/pages-description/Odontoiatria/Hollywood";
import Contact from "../../components/contact/Contact";
import BAHollywood from "../../BA/Odontoiatria/hollywood";

export default function Hollywood() {
  return (
    <Layout>
      {/* Meta tags for SEO */}
      <Helmet>
        <title>Odontoiatria - Hollywood Smile e Faccette Dentali</title>
        <meta
          name="description"
          content="Ottieni il sorriso perfetto con il trattamento Hollywood Smile e faccette dentali. Soluzioni estetiche avanzate per un sorriso luminoso e naturale."
        />
        <meta
          name="keywords"
          content="Hollywood Smile, Faccette dentali, Trattamenti dentali, Sorriso perfetto, Odontoiatria estetica"
        />
      </Helmet>

      {/* Page Layout */}
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Odontoiatria e trattamenti dentali"
        PageTitle="Hollywood smile e Faccette"
      />
      <Hollywoodd />
      <BAHollywood />
      <Contact bgColor="gray-light-bg" />
      <Footer space />
    </Layout>
  );
}
