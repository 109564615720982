import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Manicaa from "../../components/pages-description/ChirurgiaBariatrica/Manica";
import Contact from "../../components/contact/Contact";

export default function Manica() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="La manica gastrica, o sleeve gastrectomy, è un intervento chirurgico bariatrico che riduce lo stomaco per aiutare nella perdita di peso. Scopri come funziona e i benefici per la salute."
        />
        <meta
          name="keywords"
          content="chirurgia bariatrica, manica gastrica, sleeve gastrectomy, chirurgia per obesità, perdita di peso, trattamento obesità, chirurgia dimagrante, miglioramento salute"
        />
        <meta name="author" content="Chirurgia Bariatrica" />
        <meta
          property="og:title"
          content="Manica Gastrica o Sleeve Gastrectomy | Chirurgia Bariatrica"
        />
        <meta
          property="og:description"
          content="La manica gastrica è una chirurgia bariatrica che aiuta a ridurre il peso e migliorare la salute. Scopri come la sleeve gastrectomy può essere una soluzione efficace contro l'obesità."
        />
        <meta property="og:image" content="URL_TO_IMAGE.jpg" />
        <meta property="og:url" content="URL_TO_MANICA_PAGE" />
        <meta
          name="twitter:title"
          content="Manica Gastrica o Sleeve Gastrectomy | Chirurgia Bariatrica"
        />
        <meta
          name="twitter:description"
          content="La manica gastrica è un intervento chirurgico che aiuta a perdere peso, ridurre i rischi associati all'obesità e migliorare la qualità della vita."
        />
        <meta name="twitter:image" content="URL_TO_IMAGE.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <title>
          Manica Gastrica o Sleeve Gastrectomy | Chirurgia Bariatrica
        </title>
      </Helmet>

      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Chirurgia Bariatrica"
        PageTitle="Manica Gastrica o Sleeve Gastrectomy"
      />
      <Manicaa />
      <Contact bgColor="gray-light-bg" />
      <Footer space />
    </Layout>
  );
}
