import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Sixx from "../../components/pages-description/ChirugiaPlastica/six";
import Contact from "../../components/contact/Contact";

export default function Six() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Scopri il lifting delle braccia e cosce, un intervento di chirurgia plastica che migliora l'elasticità della pelle e la forma del corpo, restituendo una silhouette tonica e snella."
        />
        <meta
          name="keywords"
          content="chirurgia plastica, lifting braccia, lifting coscia, lifting corpo, chirurgia estetica, ringiovanimento, miglioramento corpo"
        />
        <meta name="author" content="Chirurgia Plastica" />
        <meta
          property="og:title"
          content="Lifting Braccia e Coscia | Chirurgia Plastica"
        />
        <meta
          property="og:description"
          content="Il lifting delle braccia e cosce è un intervento di chirurgia plastica che aiuta a migliorare l'elasticità della pelle e la forma del corpo, riducendo la pelle in eccesso e tonificando la zona."
        />
        <meta property="og:image" content="URL_TO_IMAGE.jpg" />
        <meta property="og:url" content="URL_TO_LIFTING_PAGE" />
        <meta
          name="twitter:title"
          content="Lifting Braccia e Coscia | Chirurgia Plastica"
        />
        <meta
          name="twitter:description"
          content="Scopri il lifting delle braccia e cosce per ottenere una silhouette più tonica e snella. Un trattamento sicuro e professionale di chirurgia plastica."
        />
        <meta name="twitter:image" content="URL_TO_IMAGE.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <title>Lifting Braccia e Coscia | Chirurgia Plastica</title>
      </Helmet>

      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Chirurgia Plastica"
        PageTitle="SLIFTING BRACCIA E COSCIA"
      />
      <Sixx />
      <Contact bgColor="gray-light-bg" />
      <Footer space />
    </Layout>
  );
}
