import React from "react";
import { Helmet } from "react-helmet"; // Import Helmet for meta tags
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import TeamMember from "../../components/pages-description/TrapiantoCapelli/zaffiro";
import Contact from "../../components/contact/Contact";
import BAzaffiro from "../../BA/TrapiantoCapelli/BA-zaffiro";

export default function TrapiantoCapelli() {
  return (
    <>
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>Trapianto Capelli - Zaffiro</title>
        <meta
          name="description"
          content="Scopri il trapianto capelli Zaffiro con risultati visibili prima e dopo. Offriamo soluzioni professionali e personalizzate."
        />
        <meta
          name="keywords"
          content="trapianto capelli, zaffiro, risultati prima e dopo, cura dei capelli, trapianto estetico"
        />
        <meta property="og:title" content="Trapianto Capelli - Zaffiro" />
        <meta
          property="og:description"
          content="Guarda i risultati straordinari del trapianto capelli Zaffiro. Contattaci per una consulenza personalizzata."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="assets/img/Design sans titre (0).png"
        />
      </Helmet>

      {/* Page Layout */}
      <Layout>
        <Navbar darkBg />
        <PageHeader HeaderTitle="Trapianto Capelli" PageTitle="Zaffiro" />
        <TeamMember />
        <BAzaffiro />
        <Contact bgColor="gray-light-bg" />
        <Footer space />
      </Layout>
    </>
  );
}
