import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Palloncinoo from "../../components/pages-description/ChirurgiaBariatrica/palloncino";
import Contact from "../../components/contact/Contact";

export default function Palloncino() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Il palloncino gastrico è un trattamento efficace per il controllo del peso, riducendo la quantità di cibo che lo stomaco può contenere. Scopri come funziona e i benefici di questo intervento."
        />
        <meta
          name="keywords"
          content="chirurgia bariatrica, palloncino gastrico, trattamento obesità, controllo del peso, chirurgia per dimagrire, riduzione dello stomaco, trattamento chirurgico"
        />
        <meta name="author" content="Chirurgia Bariatrica" />
        <meta
          property="og:title"
          content="Palloncino Gastrico | Chirurgia Bariatrica"
        />
        <meta
          property="og:description"
          content="Il palloncino gastrico è una soluzione chirurgica per la perdita di peso che aiuta a ridurre la quantità di cibo che lo stomaco può contenere. Scopri tutti i dettagli del trattamento."
        />
        <meta property="og:image" content="URL_TO_IMAGE.jpg" />
        <meta property="og:url" content="URL_TO_PALLONCINO_PAGE" />
        <meta
          name="twitter:title"
          content="Palloncino Gastrico | Chirurgia Bariatrica"
        />
        <meta
          name="twitter:description"
          content="Il palloncino gastrico è un trattamento efficace per la perdita di peso. Scopri come funziona e i benefici di questo intervento chirurgico."
        />
        <meta name="twitter:image" content="URL_TO_IMAGE.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <title>Palloncino Gastrico | Chirurgia Bariatrica</title>
      </Helmet>

      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Chirurgia Bariatrica"
        PageTitle="Palloncino Gastrico"
      />
      <Palloncinoo />
      <Contact bgColor="gray-light-bg" />
      <Footer space />
    </Layout>
  );
}
