import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout/Layout";
import Navbar from "../../components/layout/header/Navbar";
import HeroSix from "./Hero";
import Team from "../../components/pages-description/Service";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/layout/footer/Footer";
import AboutTwo from "../../components/about/AboutTwo";

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <title>Home - Medical Plus</title>
        <meta
          name="description"
          content="Welcome to Medical Plus! We offer premium services to rejuvenate your aesthetic needs with world-class support and care."
        />
        <meta
          name="keywords"
          content="Medical Plus, aesthetic treatments, Istanbul, follow-up care, 5-star services, aesthetic surgery"
        />
        <meta property="og:title" content="Home - Medical Plus" />
        <meta
          property="og:description"
          content="Discover world-class aesthetic solutions with Medical Plus. Plan your journey to rejuvenation now."
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Navbar darkBg />
      <HeroSix />
      <AboutTwo />
      <Team />
      <Contact bgColor="gray-light-bg" />
      <Footer space />
    </Layout>
  );
}
