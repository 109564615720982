import React from "react";
import { Helmet } from "react-helmet"; // Import react-helmet
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Impiantii from "../../components/pages-description/Odontoiatria/impianti";
import Contact from "../../components/contact/Contact";

export default function Impianti() {
  return (
    <Layout>
      {/* Meta tags for SEO */}
      <Helmet>
        <title>Impianti Dentali e Chirurgia Odontoiatrica</title>
        <meta
          name="description"
          content="Scopri i nostri servizi di impianti dentali e chirurgia odontoiatrica avanzata per un sorriso sano e duraturo."
        />
        <meta
          name="keywords"
          content="Impianti dentali, Chirurgia odontoiatrica, Odontoiatria, Trattamenti dentali, Sorriso sano"
        />
      </Helmet>

      {/* Page Layout */}
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Odontoiatria e trattamenti dentali"
        PageTitle="Impianti e Chirurgia Odontoiatrica"
      />
      <Impiantii />
      <Contact bgColor="gray-light-bg" />
      <Footer space />
    </Layout>
  );
}
