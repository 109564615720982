import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Senoo from "../../components/pages-description/ChirugiaPlastica/Seno";
import Contact from "../../components/contact/Contact";
import BASeno from "../../BA/ChurigiaPlastica/seno";

export default function Seno() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Scopri la Chirurgia Plastica per l'aumento del seno e le protesi mammarie. Un intervento sicuro per migliorare l'aspetto del seno e aumentare la fiducia in sé stesse."
        />
        <meta
          name="keywords"
          content="chirurgia plastica, seno, protesi seno, aumento seno, protesi mammarie, chirurgia estetica, miglioramento aspetto seno"
        />
        <meta name="author" content="Chirurgia Plastica" />
        <meta
          property="og:title"
          content="Seno e Protesi | Chirurgia Plastica"
        />
        <meta
          property="og:description"
          content="Interventi di Chirurgia Plastica per l'aumento del seno e le protesi mammarie. Scopri come migliorare l'aspetto del tuo seno con interventi sicuri e professionali."
        />
        <meta property="og:image" content="URL_TO_IMAGE.jpg" />
        <meta property="og:url" content="URL_TO_SENO_PAGE" />
        <meta
          name="twitter:title"
          content="Seno e Protesi | Chirurgia Plastica"
        />
        <meta
          name="twitter:description"
          content="Aumento seno e protesi mammarie con Chirurgia Plastica. Scopri come migliorare il tuo seno in modo sicuro e naturale."
        />
        <meta name="twitter:image" content="URL_TO_IMAGE.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <title>Seno e Protesi | Chirurgia Plastica</title>
      </Helmet>

      <Navbar darkBg />
      <PageHeader HeaderTitle="Chirurgia Plastica" PageTitle="SENO E PROTESI" />
      <Senoo />
      <BASeno />
      <Contact bgColor="gray-light-bg" />
      <Footer space />
    </Layout>
  );
}
