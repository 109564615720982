import React from "react";
import { Helmet } from "react-helmet"; // Import react-helmet
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Dhi from "../../components/pages-description/TrapiantoCapelli/dhi";
import Contact from "../../components/contact/Contact";
import BAdhi from "../../BA/TrapiantoCapelli/BA-dhi";

export default function DHI() {
  return (
    <Layout>
      {/* Meta tags for SEO */}
      <Helmet>
        <title>Trapianto Capelli - Tecnica DHI</title>
        <meta
          name="description"
          content="Scopri la tecnica DHI per il trapianto di capelli: un metodo avanzato e innovativo per risultati naturali e duraturi."
        />
        <meta
          name="keywords"
          content="Trapianto capelli, Tecnica DHI, Trapianto DHI, DHI, Trapianto avanzato"
        />
      </Helmet>

      {/* Page layout */}
      <Navbar darkBg />
      <PageHeader HeaderTitle="Trapianto Capelli" PageTitle="Tecnica DHI" />
      <Dhi />
      <BAdhi />
      <Contact bgColor="gray-light-bg" />
      <Footer space />
    </Layout>
  );
}
