import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Liftingg from "../../components/pages-description/ChirugiaPlastica/lifting";
import Contact from "../../components/contact/Contact";

export default function Lifting() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Scopri tutto sulla Chirurgia Plastica, in particolare sul lifting braccia e coscia. Un trattamento estetico che migliora l'aspetto della pelle."
        />
        <meta
          name="keywords"
          content="chirurgia plastica, lifting braccia, lifting coscia, lifting corpo, estetica, ringiovanimento, chirurgia estetica"
        />
        <meta name="author" content="Chirurgia Plastica" />
        <meta
          property="og:title"
          content="Lifting Braccia e Coscia | Chirurgia Plastica"
        />
        <meta
          property="og:description"
          content="Trattamenti di lifting braccia e coscia per migliorare l'aspetto della pelle e ringiovanire il corpo. Scopri i benefici della chirurgia plastica."
        />
        <meta property="og:image" content="URL_TO_IMAGE.jpg" />
        <meta property="og:url" content="URL_TO_LIFTING_PAGE" />
        <meta
          name="twitter:title"
          content="Lifting Braccia e Coscia | Chirurgia Plastica"
        />
        <meta
          name="twitter:description"
          content="Scopri i benefici del lifting braccia e coscia con la Chirurgia Plastica. Migliora l'aspetto della tua pelle e ringiovanisci il tuo corpo."
        />
        <meta name="twitter:image" content="URL_TO_IMAGE.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <title>Lifting Braccia e Coscia | Chirurgia Plastica</title>
      </Helmet>

      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Chirurgia Plastica"
        PageTitle="LIFTING BRACCIA E COSCIA"
      />
      <Liftingg />
      <Contact bgColor="gray-light-bg" />
      <Footer space />
    </Layout>
  );
}
