import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Corpoo from "../../components/pages-description/ChirugiaPlastica/Corpoo";
import Contact from "../../components/contact/Contact";
import BACorpo from "../../BA/ChurigiaPlastica/corpo";

export default function Corpo() {
  return (
    <Layout>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Chirurgia Plastica: Addominoplastica e Liposcultura Vaser</title>
        <meta
          name="description"
          content="Scopri tutto sulla Chirurgia Plastica, con un focus su Addominoplastica e Liposcultura Vaser. Informazioni dettagliate, procedure e benefici per il tuo corpo."
        />
        <meta
          name="keywords"
          content="chirurgia plastica, addominoplastica, liposcultura vaser, interventi chirurgici, estetica, bellezza, chirurgia estetica, corpo, miglioramento corporeo"
        />
        <meta name="author" content="Your Name or Your Clinic Name" />
        <meta
          property="og:title"
          content="Chirurgia Plastica: Addominoplastica e Liposcultura Vaser"
        />
        <meta
          property="og:description"
          content="Approfondisci la Chirurgia Plastica con i dettagli su Addominoplastica e Liposcultura Vaser per un corpo più snodato e attraente."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="URL_OF_YOUR_PAGE_HERE" />
        <meta
          property="og:image"
          content="URL_TO_AN_IMAGE_RELATED_TO_THE_CONTENT"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Chirurgia Plastica: Addominoplastica e Liposcultura Vaser"
        />
        <meta
          name="twitter:description"
          content="Informazioni e dettagli sulla Chirurgia Plastica, focalizzandosi su Addominoplastica e Liposcultura Vaser."
        />
        <meta
          name="twitter:image"
          content="URL_TO_AN_IMAGE_RELATED_TO_THE_CONTENT"
        />
      </Helmet>

      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Chirurgia Plastica"
        PageTitle="CORPO ADDOMINOPLASTICA E LIPOSCULTURA VASER"
      />
      <Corpoo />
      <BACorpo />
      <Contact bgColor="gray-light-bg" />
      <Footer space />
    </Layout>
  );
}
