import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Momyy from "../../components/pages-description/ChirugiaPlastica/Momy";
import Contact from "../../components/contact/Contact";

export default function Momy() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Scopri il Mommy Makeover, un trattamento di chirurgia plastica che aiuta le donne a recuperare la forma fisica post-parto, migliorando il corpo e l'autostima."
        />
        <meta
          name="keywords"
          content="chirurgia plastica, mommy makeover, corpo post-parto, estetica post-parto, ringiovanimento, chirurgia estetica, lifting corpo"
        />
        <meta name="author" content="Chirurgia Plastica" />
        <meta
          property="og:title"
          content="Mommy Makeover | Chirurgia Plastica"
        />
        <meta
          property="og:description"
          content="Il Mommy Makeover è il trattamento di chirurgia plastica che aiuta le donne a recuperare la forma fisica dopo la gravidanza. Scopri come può migliorare l'aspetto del tuo corpo."
        />
        <meta property="og:image" content="URL_TO_IMAGE.jpg" />
        <meta property="og:url" content="URL_TO_MOMMY_PAGE" />
        <meta
          name="twitter:title"
          content="Mommy Makeover | Chirurgia Plastica"
        />
        <meta
          name="twitter:description"
          content="Trattamenti di Mommy Makeover per recuperare la forma fisica post-parto. Un intervento di chirurgia plastica che aiuta le donne a sentirsi meglio con il loro corpo."
        />
        <meta name="twitter:image" content="URL_TO_IMAGE.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <title>Mommy Makeover | Chirurgia Plastica</title>
      </Helmet>

      <Navbar darkBg />
      <PageHeader HeaderTitle="Chirurgia Plastica" PageTitle="MOMMY MAKEOVER" />
      <Momyy />
      <Contact bgColor="gray-light-bg" />
      <Footer space />
    </Layout>
  );
}
