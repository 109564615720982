import React from "react";
import { Helmet } from "react-helmet"; // Import react-helmet
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import BBLL from "../../components/pages-description/ChirugiaPlastica/BBL";
import Contact from "../../components/contact/Contact";
import BaBbl from "../../BA/ChurigiaPlastica/bbl";

export default function BBL() {
  return (
    <Layout>
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>BBL - Brazilian Butt Lift | Chirurgia Plastica</title>
        <meta
          name="description"
          content="Scopri il BBL o Brazilian Butt Lift: intervento di chirurgia plastica per ottenere un gluteo tonico e modellato in modo naturale."
        />
        <meta
          name="keywords"
          content="BBL, Brazilian Butt Lift, Chirurgia plastica, Rimodellamento glutei, Chirurgia estetica"
        />
      </Helmet>

      {/* Page Layout */}
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Chirurgia Plastica"
        PageTitle="BBL o Brazilian Butt Lift"
      />
      <BBLL />
      <BaBbl />
      <Contact bgColor="gray-light-bg" />
      <Footer space />
    </Layout>
  );
}
